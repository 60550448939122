.selectSeason_container {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.selectSeason_container_body {
  display: flex;
  width: 200px;
  align-items: center;

  /* > select {
      background-color: transparent; 
      width: 100%;
      color: #ffffff;
      border: 1px solid var(--text-color-1); 
      padding: 15px; 
      border-radius: 10px; 
      font-weight: bold;
      font-size: large;
      align-items: center;
      text-align: center;
    } */

  /* select:hover,
    select:focus {
      background-color: transparent;
      border-color: #255fd6;
      outline: none; 
    } */

  /* select option {
      background-color: var(--bg-color-2);
      line-height: 6;
      color: #ffffff;
      padding: 15px;
      border-radius: 10px;
    } */
}
.option_container {
  padding: 10px;
  border: 1px solid #255fd6;
  border-radius: 5px;
  background-color: var(--background-color-7);
  color: #ffffff !important;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border-radius: 15px;
  font-weight: bold;
  font-size: large;
  align-items: center;
  text-align: center;
}
.option {
  padding: 10px;
  border: 1px solid #255fd6;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff !important;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border-radius: 15px;
  font-weight: bold;
  font-size: large;
  align-items: center;
  text-align: center;
}
.option_item {
  background-color: var(--bg-color-2);
  line-height: 6;
  color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
}
.singleSeason {
  cursor: default;
  background-color: transparent !important;
}
