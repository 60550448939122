.carousel_container {
}

.carousel_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.carousel_container > h4 {
  font-size: 21px !important;
}

.slick_prev:hover > svg > path {
  stroke: var(--white-color);
  /* color: #000; */
}
.slick_next:hover > svg > path {
  stroke: var(--white-color);
  /* color: #000; */
}

.carousel_header_btns {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  display: flex;
  padding: 7px;
  align-items: center;
  gap: 20px;
  border: 1px solid var(--Black-12, #1f1f1f);
  background: var(--Black-06, #0f0f0f);
}
