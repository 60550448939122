.favourrites_container {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}

.favourrites_container h5 {
  padding: 1rem;
}

.favourrites_container_box {
  /* min-height: 50vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}
