/** Start of font family **/

/* Manrope Regular */
@font-face {
  font-family: "Manrope";
  src: url("./fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: 400; /* Normal */
  font-style: normal;
}

/* Manrope Bold */
@font-face {
  font-family: "Manrope";
  src: url("./fonts/Manrope-Bold.ttf") format("truetype");
  font-weight: 700; /* Bold */
  font-style: normal;
}

/* Manrope ExtraBold */
@font-face {
  font-family: "Manrope";
  src: url("./fonts/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 800; /* Extra Bold */
  font-style: normal;
}

/* Manrope Light */
@font-face {
  font-family: "Manrope";
  src: url("./fonts/Manrope-Light.ttf") format("truetype");
  font-weight: 300; /* Light */
  font-style: normal;
}

/* Manrope ExtraLight */
@font-face {
  font-family: "Manrope";
  src: url("./fonts/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: 200; /* Extra Light */
  font-style: normal;
}

/* Manrope Medium */
@font-face {
  font-family: "Manrope";
  src: url("./fonts/Manrope-Medium.ttf") format("truetype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

/* Manrope SemiBold */
@font-face {
  font-family: "Manrope";
  src: url("./fonts/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600; /* Semi Bold */
  font-style: normal;
}

/* ============== */

/* BebasNeue Regular */
@font-face {
  font-family: "BebasNeue-Regular";
  src: url("./fonts/BebasNeue-Regular.ttf") format("truetype");
}

/* BungeeShade Regular */
@font-face {
  font-family: "BungeeShade-Regular";
  src: url("./fonts/BungeeShade-Regular.ttf") format("truetype");
}

/* CedarvilleCursive Regular */
@font-face {
  font-family: "CedarvilleCursive-Regular";
  src: url("./fonts/CedarvilleCursive-Regular.ttf") format("truetype");
}

body {
  /* font-family: "Manrope", sans-serif; */
}
.App_Container {
  min-height: 100vh;
  /* width: 100vw; */
}

.custom-nav-dropdown .dropdown-toggle::after {
  display: none !important; /* Hides the default arrow */
}

.custom-nav-dropdown .dropdown-menu {
  background-color: var(--background-color-4);
  border-radius: 16px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  bottom: 10px; /* Positions buttons at the bottom */
  top: auto;
  transform: translateY(0);
  width: 30px;
  opacity: 1;
  z-index: 1;
}

.carousel-control-prev {
  left: 20px; /* Aligns left button to bottom-left */
  display: flex;
  padding: 2px 6px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.carousel-control-next {
  right: 20px; /* Aligns right button to bottom-right */
  display: flex;
  padding: 2px 6px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 28px;
  height: 28px;
}

.carousel-indicators {
  bottom: 10px; /* Positions indicators at the bottom center */
}

.carousel-indicators li {
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.accordion-button::after {
  display: none !important;
}

/* button:hover {
  background-color: var(--btn-color-1);
  color: var(--white-color);
} */

@media screen and (min-width: 230px) and (max-width: 430px) {
  .custom-nav-dropdown .dropdown-menu {
    padding: 5px;
  }
  .custom-nav-dropdown svg {
    width: 20px;
  }
}
