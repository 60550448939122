.sideBar_container {
  display: flex;
  /* padding: 1rem 4rem; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sideBar_container_logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sideBar_container_menu_box {
}

.sideBar_container_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-end;
  gap: 30px;
}

.sideBar_container_menu_input form {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.search-input {
  width: 0;
  opacity: 0;
  padding: 0;
  border: none;
  outline: none;
  transition: all 0.5s ease-in-out;
}
.toggle_btn {
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 4;
  position: absolute;
  top: 20px;
  left: 25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

.search-input.active {
  width: 200px; /* Adjust as needed */
  opacity: 1;
  border-radius: 10px;
  padding: 8px;
  border: 1px solid var(--text-color-4);
}

.sideBar_container_menu_icon_dropdow_item {
  padding: 1rem 2rem;
  display: flex;
  align-items: flex-start;
  text-align: start;
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  /* letter-spacing: 0.15px; */
  border-radius: 20px;
}
.sideBar_container_menu_icon_dropdow_item a:hover {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

@media screen and (min-width: 230px) and (max-width: 460px) {
  .sideBar_container_logo a img {
    width: 80px;
  }
  .sideBar_container {
    padding: 0rem 1rem;
    padding-left: 40px;
  }
  .sideBar_container_menu {
    gap: 10px;
  }
  .search-input.active {
    width: 180px;
    padding: 2px 4px;
  }
  .sideBar_container_menu_icon_dropdow_item {
    padding: 0.5rem 0.7rem;
  }
  .sideBar_container_menu_input svg {
    width: 20px;
  }
  .toggle_btn {
    /* position: static; */
    left: 8px;
    top: 6px;
    height: auto;
  }
}
