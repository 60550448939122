.modalContent {
  background-color: #1e1e1e !important; /* Dark background */
  color: #ffffff !important; /* White text */
  border-radius: 6px;
  padding: 20px;
  overflow: hidden;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #444;
  padding: 15px;
}

.modalTitleRow {
  display: flex;
  align-items: center;
  /* gap: 10px; */
}

.modalTitle {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.modalBody {
  color: #ddd;
  font-size: 16px;
  padding: 20px;
  text-align: center;
}

.modalFooter {
  border-top: 1px solid #444;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.deleteButton {
  border-radius: 15px;
  background: transparent;
  border: solid 1px var(--error-color);
  min-width: 50%;
  height: 2.5rem;
  display: flex;
  color: var(--error-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  align-items: center;
  text-align: center;
  padding: 0.1rem 3rem;
}

.deleteButton span {
}

.deleteButton:hover {
  background: var(--error-color) !important;
  color: var(--white-color);
  border: solid 1px var(--error-color);
}

.cancelButton {
  border-radius: 15px;
  background-color: #6c757d !important; /* Grey cancel button */
  border: none;
  height: 2.5rem;
  display: flex;
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  align-items: center;
  text-align: center;
  padding: 0.1rem 3rem;
}

.cancelButton:hover {
  background-color: #5a6268 !important;
}

.closeIcon {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.closeIcon:hover {
  color: #dc3545;
}
