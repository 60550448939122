.AuthFooter_container {
  display: flex;
  width: 100%;
  padding: 1rem 5rem;
  background-color: var(--background-color-3);
}
.AuthFooter_container_box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.AuthFooter_container_box_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 100%; */
}

.AuthFooter_container_box_body_links {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  justify-content: space-around;
  width: 80%;
}

.AuthFooter_container_box_body_links_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
}

.AuthFooter_container_box_body_links_div div {
}

.AuthFooter_container_box_body_links_div div a {
  color: var(--Grey-60, #999);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}

.AuthFooter_container_box_body_socials {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1;
}
.AuthFooter_container_box_body_socials_link {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.AuthFooter_container_box_body_socials_link a {
  display: flex;
  padding: 5px;
  align-items: center;
  /* gap: 10px; */
  border-radius: 8px;
  border: 1px solid var(--Black-15, #262626);
  background: var(--Black-10, #1a1a1a);
}

.AuthFooter_container_box_body_links_div div a:hover {
  text-decoration: underline !important;
}

.AuthFooter_container_box_body_socials_link a:hover {
  border-color: var(--white-color) !important;
}

@media screen and (min-width: 270px) and (max-width: 480px) {
  .AuthFooter_container_box_body_links {
    flex-direction: column;
  }
  .AuthFooter_container_box_body {
    flex-direction: column;
    gap: 5px;
  }
  .AuthFooter_container {
    padding: 1rem 1rem;
  }
  .AuthFooter_container_box_body_links_div {
    gap: 1px;
  }
}

@media screen and (min-width: 481px) and (max-width: 650px) {
  .AuthFooter_container_box_body_links {
    flex-direction: column;
  }
  .AuthFooter_container_box_body {
    flex-direction: column;
    gap: 5px;
  }
  .AuthFooter_container {
    padding: 1rem 1rem;
  }
  .AuthFooter_container_box_body_links_div {
    gap: 1px;
  }
}
