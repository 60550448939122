.descriptionDetails_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.descriptionDetails_container_left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 8;
}

.descriptionDetails_container_left_description {
  border-radius: 12px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 2rem;
}

.descriptionDetails_container_left_description h6 {
  color: var(--text-color-6);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}

.descriptionDetails_container_left_body {
  border-radius: 12px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.descriptionDetails_container_left_body_cast {
  display: flex;
  flex-direction: column;
}

.descriptionDetails_container_left_body_cast h6 {
  color: var(--text-color-6);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}

.descriptionDetails_container_left_body_cast div {
  display: grid;
  gap: 0.7rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  justify-content: start; /* Align items to the left */
  align-items: start; /* Align items to the top */
  overflow-wrap: break-word; /* Ensures content wraps when necessary */
}
.descriptionDetails_container_left_body_cast div label {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 0.3rem 0.5rem;
  /* width: max-content; */
  /* max-width: 100%;  */
  overflow: hidden;
  text-overflow: ellipsis; /* Shows '...' if text overflows */
  white-space: nowrap; /* Prevents wrapping */
}

.descriptionDetails_container_left_body_crow {
  display: flex;
  flex-direction: column;
}

.descriptionDetails_container_left_body_crow h6 {
  color: var(--text-color-6);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}

.descriptionDetails_container_left_body_crow div {
  display: grid;
  gap: 0.7rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  justify-content: start; /* Align items to the left */
  align-items: start; /* Align items to the top */
  overflow-wrap: break-word; /* Ensures content wraps when necessary */
}

.descriptionDetails_container_left_body_crow div label {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 0.3rem 0.5rem;
  /* width: max-content;  */
  /* max-width: 100%; */
  overflow: hidden;
  text-overflow: ellipsis; /* Shows '...' if text overflows */
  white-space: nowrap; /* Prevents wrapping */
}

.descriptionDetails_container_right {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 2;
}

.descriptionDetails_container_right_languages {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.descriptionDetails_container_right_languages_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.descriptionDetails_container_right_languages_header h6 {
  color: var(--text-color-6);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin: 0px;
}

.descriptionDetails_container_right_languages_body {
  display: grid;
  flex-direction: row;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  justify-content: start; /* Align items to the left */
  align-items: start;
}

.descriptionDetails_container_right_languages_body label {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 0.3rem 0.5rem;
}

.descriptionDetails_container_right_Ratings {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.descriptionDetails_container_right_Ratings_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.descriptionDetails_container_right_Ratings_header h6 {
  color: var(--text-color-6);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin: 0px;
}

.descriptionDetails_container_right_Ratings_body {
  display: grid;
  flex-direction: row;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  justify-content: start; /* Align items to the left */
  align-items: start;
}

.descriptionDetails_container_right_Ratings_body label {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 0.3rem 0.5rem;
}

.descriptionDetails_container_right_Gernes {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.descriptionDetails_container_right_Gernes_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.descriptionDetails_container_right_Gernes_header h6 {
  color: var(--text-color-6);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin: 0px;
}

.descriptionDetails_container_right_Gernes_body {
  display: grid;
  flex-direction: row;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  justify-content: start; /* Align items to the left */
  align-items: start; /* Align items to the top */
}

.descriptionDetails_container_right_Gernes_body label {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 0.3rem 0.5rem;
  white-space: inherit;
}

.descriptionDetails_container_right_Director {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.descriptionDetails_container_right_Director_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.descriptionDetails_container_right_Director_header h6 {
  color: var(--text-color-6);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}

.descriptionDetails_container_right_Director_body {
  display: grid;
  flex-direction: row;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  justify-content: start; /* Align items to the left */
  align-items: start;
}

.descriptionDetails_container_right_Director_body label {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 0.3rem 0.5rem;
}

@media screen and (min-width: 270px) and (max-width: 767px) {
  .descriptionDetails_container {
    flex-direction: column;
  }
}
