.star_container > svg:hover {
  transform: scale(1.1);
}
.star_container > svg {
  width: 4rem;
  cursor: pointer;
  transition: 100ms ease-in;
}

@media screen and (min-width: 270px) and (max-width: 480px) {
  .rating_container {
    width: 90%;
  }
  .star_container > svg {
    width: 3rem;
  }
}
