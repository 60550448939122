.resume_play_container {
  display: flex;
}
.resume_play_container_btn {
  border-radius: 6px;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--btn-color-1);
  background: rgba(82, 229, 194, 0);

  color: var(--btn-color-1);
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.resume_play_container_btn:hover {
  background-color: var(--btn-color-1);
  color: var(--white-color);
}
