.contactus_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: inherit;
}

.form-container {
  text-align: center;
  position: relative;
  /* max-width: 500px; */
  /* margin: 40px auto; */
  min-width: 20%;
  padding: 30px;
  background: var(--background-color-4);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(179, 161, 161, 0.215);
  color: var(--white-color);
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--white-color);
  margin-bottom: 10px;
}

.form-text {
  font-size: 14px;
  color: var(--white-color);
  margin-bottom: 20px;
}

.form-input,
.form-textarea {
  margin-bottom: 15px;

  width: 100%;
  display: flex;
  padding: 14px 10px;
  border-radius: 10px;
  border: 1px solid transparent;
  outline: none; /* Removes focus border (if needed) */
  color: black;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-input:focus,
.form-textarea:focus {
  border-color: #007bff;
  outline: none;
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.submit-button {
  width: 100%;
  background: #007bff;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.submit-button:hover {
  background: #0056b3;
}

.modal-container {
  text-align: center;
  padding: 20px;
  background: var(--background-color-8);
  box-shadow: 0 4px 10px rgba(179, 161, 161, 0.215);
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
}

.modal-message {
  font-size: 14px;
  color: #555;
}

.modal-ok-btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background: #007bff;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.modal-ok-btn:hover {
  background: #0056b3;
}
