.slick_prev {
  border-radius: 8px;
  border: 1px solid var(--Black-12, #1f1f1f);
  background: var(--Black-10, #1a1a1a);
  display: flex;
  padding: 7px;
  align-items: flex-start;
  cursor: pointer;
}

.slick_prev img {
  height: 1rem;
}

.slick_next img {
  height: 1rem;
}

.slick_next {
  border-radius: 8px;
  border: 1px solid var(--Black-12, #1f1f1f);
  background: var(--Black-10, #1a1a1a);
  display: flex;
  padding: 7px;
  align-items: flex-start;
  cursor: pointer;
}
.slick_prev:hover > svg > path {
  stroke: var(--white-color);
  /* color: #000; */
}
.slick_next:hover > svg > path {
  stroke: var(--white-color);
  /* color: #000; */
}
