.ticketBox_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.8
  ); /* Black background with slight transparency */
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 1000; */
}

.ticketBox_modal div {
  background-color: transparent;
}

.ticketContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.ticketBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: inherit;
  width: 100%;
}

.ticketCard {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/images/ticketCard.png");
  background-size: contain;
  background-repeat: no-repeat;
  /* height: 550px;
  width: 1250px; */
  position: relative;
  align-items: center;
  justify-content: center;

  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1250px; /* Set max width */
  height: auto;
  aspect-ratio: 1250 / 550; /* Maintain the ticket image's aspect ratio */
}

.ticketDetails {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* height: 256px;
  width: 713px; */
  justify-content: space-between;
  align-items: center;
  gap: 0.6rem;
  width: 60%; /* Adjust width relative to image */
  /* height: 50%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.movieTitle {
  font-size: 40px;
  font-family: "CedarvilleCursive-Regular";
  font-weight: 400;
  color: rgba(104, 46, 23, 1);
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
}

.playingText {
  color: #682e17;
  text-align: center;
  font-family: "BebasNeue-Regular";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.15px;
}

.hall {
  color: #682e17;
  text-align: center;
  font-family: "BungeeShade-Regular";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.15px;
}

.viewers {
  color: #682e17;
  text-align: center;
  font-family: "CedarvilleCursive-Regular";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.15px;
  border-bottom: 2px solid #682e17;
  max-width: 410px;
  /* height: 40px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin: 0px !important;
}

.dateTime {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  font-family: "CedarvilleCursive-Regular";
}

.date {
  color: #682e17;
  text-align: center;
  font-family: "CedarvilleCursive-Regular";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.15px;
  border-bottom: 2px solid #682e17;
  width: 200px;
}

.time {
  color: #682e17;
  text-align: center;
  font-family: "CedarvilleCursive-Regular";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.15px;
  width: 130px;
  border-bottom: 2px solid #682e17;
}

.playButton button {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: black;
  gap: 20px;
  font-size: 25px;
  font-family: "Manrope";
  z-index: 999;
  padding: 0.3rem 2rem;
  border-radius: 15px;
  background: var(--white-color);
  border: transparent;
}

.playButton button:hover {
  border: 1px solid (blue);
}

.playLabel {
  font-size: 18px;
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .movieTitle {
    font-size: 25px;
  }
  .hall {
    font-size: 20px;
  }

  .playingText {
    font-size: 12px;
  }

  .viewers {
    font-size: 25px;
  }
  .date {
    font-size: 18px;
  }
  .time {
    font-size: 18px;
  }
}

@media screen and (min-width: 430px) and (max-width: 767px) {
  .movieTitle {
    font-size: medium;
  }
  .hall {
    font-size: small;
  }

  .playingText {
    font-size: 12px;
  }

  .viewers {
    font-size: smaller;
  }
  .date {
    font-size: smaller;
  }
  .time {
    font-size: smaller;
  }
  .playLabel {
    font-size: 12px;
  }
  .ticketDetails {
    gap: 0.3rem;
  }
}

@media screen and (min-width: 230px) and (max-width: 429px) {
  .movieTitle {
    font-size: smaller;
  }
  .hall {
    font-size: x-small;
  }

  .playingText {
    font-size: 8px;
  }

  .viewers {
    font-size: x-small;
    max-height: fit-content;
    max-width: fit-content;
    /* height: 10px; */
  }
  .dateTime {
    width: 100%;
  }
  .date {
    font-size: x-small;
    width: 70px;
  }
  .time {
    font-size: x-small;
    width: 60px;
  }
  .playLabel {
    font-size: 12px;
  }
  .ticketDetails {
    width: auto; /* Adjust width relative to image */
    /* height: ; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 0.1rem;
  }
}
