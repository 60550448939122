@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;600&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  /* height: 100vh;
  width: 100vw; */
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  background: var(--background-color-1) !important;
  color: var(--white-color) !important;
}
:root {
  --background-color-1: #1a1a1a;
  --background-color-2: #111217;
  --background-color-3: rgba(15, 15, 15, 0.5);
  --background-color-4: #101010;
  --background-color-5: rgba(0, 0, 0, 0.2);
  --background-color-6: #d9d9d9;
  --background-color-7: #0f0f0f;
  --background-color-8: #262626;
  --background-color-9: #202020;
  --background-color-10: #0f0f0f;
  --background-color-11: rgba(20, 20, 20, 0.6);
  --success-color: #22bb33;
  --white-color: #fff;
  --error-color: #ff3333;
  --text-color-1: #003583;
  --text-color-2: #017bf4;
  --text-color-3: #bcbcbc;
  --text-color-4: #ccc;
  --text-color-5: #e3e3e3;
  --text-color-6: #999;
  --text-color-7: #585858;
  --btn-color-1: #017bf4;
  --btn-hover-color: #015bbd;

  --bg-color-1: linear-gradient(118deg, #003583 0%, #52e5c2 97.25%);
  /* --btn-color-1: #1b47e4; */
  --nav-color: #252730;
  --input-color: #3e404a;
  --bg-color-2: linear-gradient(118deg, #003583 0%, #52e5c2 97.25%);
  --disabled-btn: rgba(82, 229, 194, 0.41);
  --bg-color-3: var(--Black-08, #141414);
  --bg-border-grey: #1f1f1f;
}
a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
  padding: 0px;
  line-height: unset !important;
}
/* nav {
  > button {
    background-color: var(--background-color-1);
    border: none;
    outline: none;
    z-index: 99999;
  }
} */

p {
  /* margin: 0px !important; */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80%;
  background: var(--nav-color);
  z-index: 3;
}

.ul,
.li {
  margin: 0;
  padding: 0;
}

.ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

.li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .background {
    width: 80%;
  }
}
