.layout_container {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  /* padding: 1rem 4rem; */
  gap: 2rem;
}

.layout_container_header {
  padding: 0rem 4rem;
  padding-top: 1rem;
}
.layout_container_outlet {
  min-height: inherit;
  padding: 0rem 4rem;
}

@media screen and (min-width: 230px) and (max-width: 460px) {
  .layout_container {
    /* padding: 0rem 0.6rem; */
    gap: 1rem;
  }

  .layout_container_header {
    padding: 0rem 0.6rem;
  }
  .layout_container_outlet {
    min-height: inherit;
    padding: 0rem 0.6rem;
  }
}
