.ticket_card_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.tickets {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid var(--btn-color-1);
  background: rgba(217, 217, 217, 0);
  justify-content: center;
  align-items: center;
}

.tickets h5 {
  color: var(--btn-color-1);
  font-style: normal;
  font-weight: 700;
  /* line-height: 102.1%; */
  padding: 2px;
}

.tickets_body {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--btn-color-1);
  justify-content: space-between;
}

.tickets_body_left {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: flex-start;

  padding-right: 20px;
  background-color: transparent;
  border: none;
  color: var(--white-color);
}

.tickets_body_left_header label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.1%; /* 28.42px */
}

.tickets_body_right_header label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.1%; /* 28.42px */
}

.tickets_body label span {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 102.1%; /* 24.504px */
}

.tickets_body_right {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: flex-start;
  border-left: 1px solid var(--btn-color-1) !important;
  padding-right: 20px;
  background-color: transparent;
  border: none;
  color: var(--white-color);
}

.tickets_body_right:hover {
  background-color: var(--btn-color-1);
  color: var(--white-color);
}

.disabled label {
  color: var(--text-color-6);
  opacity: 0.5;
  cursor: not-allowed;
}
.disabled:hover {
  color: var(--text-color-6);
  background-color: transparent !important;
}
