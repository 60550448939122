/* Main container */
.wlcFaqContainer {
  /* padding: 20px; */
  /* background-color: #121212; Dark background */
  color: white;
}

/* Grid Layout */
.wlcFaqGrid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 20px;
}

/* Left and Right Columns */
.wlcFaqColumn {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Accordion Card */
.wlcFaqCard {
  background: transparent; /* Dark card */
  border-radius: 8px;
  overflow: hidden;
  transition: 0.3s;
}
.span_box {
  width: 100%;
  height: 1px; /* Border thickness */
  background: linear-gradient(
    90deg,
    rgba(0, 147, 229, 0) 0%,
    #004ee5 16.67%,
    rgba(0, 119, 229, 0) 100%
  );
}
.wlcFaqColumnBox {
  display: flex;
  flex-direction: column;
}

/* Accordion Header */
.wlcFaqHeader {
  background: transparent;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  transition: 0.3s;
}

.wlcFaqHeader:hover {
  background: linear-gradient(
      222deg,
      rgba(1, 123, 244, 0.5) -53.57%,
      rgba(1, 123, 244, 0) 53.69%
    ),
    #0f0f0f;
}

/* Number Box */
.wlcFaqId {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  margin-right: 10px;
}

/* Question Text */
.wlcFaqQuestion {
  flex-grow: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 33px */
  text-align: start;
}

/* Toggle + / - Button */
.wlcFaqToggle {
  font-size: 20px;
  font-weight: bold;
  color: white;
  transition: 0.3s;
}

/* Accordion Body (Hidden by default) */
.wlcFaqBody {
  background: linear-gradient(
      222deg,
      rgba(1, 123, 244, 0.5) -53.57%,
      rgba(1, 123, 244, 0) 53.69%
    ),
    #0f0f0f;

  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

/* Expanded State */
.wlcFaqBody.open {
  max-height: 200px; /* Adjust based on content size */
  opacity: 1;
}

/* Inner Content */
.wlcFaqBodyContent {
  padding: 15px;
  color: white;
  font-size: 14px;
  line-height: 1.5;
}

.wlcFaqHeader_background {
  background: linear-gradient(
      222deg,
      rgba(1, 123, 244, 0.5) -53.57%,
      rgba(1, 123, 244, 0) 53.69%
    ),
    #0f0f0f;
}

@media screen and (min-width: 230px) and (max-width: 460px) {
  .wlcFaqGrid {
    display: flex;
    flex-direction: column;
  }
}
