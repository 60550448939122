.details_screen_Placeholder {
  display: flex;
  width: 100%;
  background-color: var(--background-color-2);
  justify-content: center;
}
.details_screen_Placeholder:global(.placeholder-glow) {
  background-color: rgba(230, 14, 14, 0.2); /* Change color to light blue */
}

@media screen and (min-width: 270px) and (max-width: 480px) {
  .details_screen_Placeholder {
    height: 30vh; /* Smaller height for small screens */
  }
}

@media screen and (min-width: 481px) and (max-width: 800px) {
  .details_screen_Placeholder {
    height: 40vh; /* Smaller height for small screens */
  }
}
@media screen and (min-width: 801px) and (max-width: 1024px) {
  .details_screen_Placeholder {
    height: 50vh; /* Smaller height for small screens */
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .details_screen_Placeholder {
    height: 70vh; /* Smaller height for small screens */
  }
}

@media (min-width: 1200px) {
  .details_screen_Placeholder {
    height: 90vh; /* Larger height for larger screens */
  }
}
