.landing_screen {
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, #192b2f 25%, #1b2e2f 40%, #222a22 100%);
}

.section_header {
  height: 100vh;
  background: rgb(2, 0, 36);
  background: linear-gradient(
      180deg,
      #16262ac3 8%,
      rgba(20, 20, 20, 0.379) 20%,
      #1b2e2f 100%
    ),
    url("../../assets/images/welcomeScreenBg.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.section_header_top {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.section_header_top_btn > a {
  border-radius: 8px;
  background: var(--text-color-2);
  border: none;
  padding: 0.4rem 1.4rem;
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.section_header_top_btn > a:hover {
  background-color: var(--btn-hover-color);
}

.section_header_body {
  display: flex;
  padding: 3% 20%;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.section_header_body > h1 {
  color: var(--white-color);
  text-align: center;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 105px */
  margin: 0px;
  font-family: Manrope;
}

.section_header_body > div > h2 {
  color: var(--white-color);
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.74px;
  margin: 0px;
}

.section_header_body > div > h5 {
  color: var(--text-color-6);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.section_header_body > div > a {
  border-radius: 8px;
  background: var(--text-color-2);
  border: none;
  padding: 0.4rem 1.4rem;
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.section_header_body > div > a:hover {
  background-color: var(--btn-hover-color);
}

.section_various_devices {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  padding: 4rem;
  gap: 2rem;
}

.section_various_devices_header {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: start;
}

.section_various_devices_header > h5 {
  color: var(--white-color);

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 57px */
  margin: 0px;
}

.section_various_devices_header > label {
  color: var(--text-color-6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  max-width: 80%;
}

.section_various_devices_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  text-align: start;
}

.section_various_devices_body_card {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--Black-15, #262626);
  background: linear-gradient(
      222deg,
      rgba(1, 123, 244, 0.5) -53.57%,
      rgba(1, 123, 244, 0) 53.69%
    ),
    #0f0f0f;

  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex: 1 0 0;
}

.section_various_devices_body_card_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.section_various_devices_body_card_header_img {
  border-radius: 12px;
  border: 1px solid var(--Black-12, #1f1f1f);
  background: var(--Black-08, #141414);
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
}

.section_various_devices_body_card_header > h6 {
  color: var(--white-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  margin: 0px;
}

.section_various_devices_body_card_header_img img {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.section_various_devices_body_card_body p {
  color: var(--text-color-6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.section_faqs {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  padding: 4rem;
  gap: 1.5rem;
}

.section_faqs_body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.section_faqs_header {
}

.section_faqs_header {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  text-align: start;
}

.section_faqs_header_left > h5 {
  color: var(--white-color);

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 57px */
  margin: 0px;
}

.section_faqs_header_left > label {
  color: var(--text-color-6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  /* max-width: 80%; */
}
.section_faqs_header_right > a {
  border-radius: 8px;
  background: var(--text-color-2);
  border: none;
  padding: 0.4rem 1.4rem;
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.section_faqs_header_right > a:hover {
  background-color: var(--btn-hover-color);
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .section_various_devices_body {
    flex-direction: column;
  }
  .section_header_body {
    padding: 2rem;
  }

  .section_header_body > h1 {
    font-size: 50px;
  }

  .section_header_body > div > h2 {
    font-size: 38px;
  }

  .section_header_body > div > h5 {
  }
}

@media screen and (min-width: 220px) and (max-width: 425px) {
  .section_various_devices_body {
    flex-direction: column;
  }
  .section_header_body {
    padding: 1rem;
  }

  .section_header_body > h1 {
    font-size: 40px;
  }

  .section_header_body > div > h2 {
    font-size: 28px;
  }

  .section_header_body > div > h5 {
  }

  .section_faqs {
    padding: 1rem;
  }

  .section_faqs_header_left > h5 {
    font-size: 26px;
  }

  .section_faqs_header_left > label {
    font-size: 16px;
  }

  .section_various_devices {
    padding: 1rem;
  }
}
