.grouppurchasepayment_container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  border-radius: 12px;
  background: var(--background-color-9);
  padding: 4rem;
  gap: 20px;
}

.grouppurchasepayment_container_box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2rem;
  align-items: center;
}

.grouppurchasepayment_container_box_wallet {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.grouppurchasepayment_container_box_wallet div {
}
.grouppurchasepayment_container_box_wallet div div {
  display: flex;
  flex-direction: column;
}

.grouppurchasepayment_container_box_upi {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.grouppurchasepayment_container_box_upi div {
}

.grouppurchasepayment_container_box_wallet_box {
  border-radius: 20px;
  background: var(--background-color-5);
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: solid 1px transparent;
}

.grouppurchasepayment_container_box_wallet_box span {
  display: flex;
  align-items: center;
  padding: 1.3rem;
}

.grouppurchasepayment_container_box_wallet_box:hover {
  border: solid 1px var(--white-color);
}

.grouppurchasepayment_container_box_wallet_box div label {
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.grouppurchasepayment_container_box_upi_box {
  border-radius: 20px;
  background: var(--background-color-5);
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  border: solid 1px transparent;
}

.grouppurchasepayment_container_box_upi_box span {
  display: flex;
  align-items: center;
  padding: 1.3rem;
}
.grouppurchasepayment_container_box_upi_box:hover {
  border: solid 1px var(--white-color);
}

.grouppurchasepayment_container_box_upi_box div label {
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.grouppurchasepayment_container_box_upi_box div label:nth-child(2) {
  color: var(--text-color-7);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.grouppurchasepayment_container_box_upi div div {
  display: flex;
  flex-direction: column;
}
.grouppurchasepayment_container_Add_money {
  max-width: 200px;
}
.grouppurchasepayment_container_Add_money_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  background: var(--background-color-5);
  padding: 1rem;
  cursor: pointer;
  border: solid 1px transparent;
}
.grouppurchasepayment_container_Add_money_box:hover {
  border: solid 1px var(--white-color);
}

.grouppurchasepayment_container_Add_money_box label {
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.custome_center {
  justify-content: center;
}

.grouppurchasepayment_container_box_or {
  font-size: 20px;
  font-weight: 500;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
}

@media screen and (min-width: 461px) and (max-width: 900px) {
  .grouppurchasepayment_container_box {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 230px) and (max-width: 460px) {
  .grouppurchasepayment_container_box {
    flex-direction: column-reverse;
  }
  .grouppurchasepayment_container {
    padding: 1rem;
    min-height: 30vh;
  }
}
