.boom-animation .modal-dialog {
  animation: boomIn 0.3s ease-out;
}

@keyframes boomIn {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  80% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
