/* Main container */
.faqContainer {
  /* padding: 20px; */
  /* background-color: #121212; Dark background */
  color: white;
}

/* Grid Layout */
.faqGrid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 20px;
}

/* Left and Right Columns */
.faqColumn {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Accordion Card */
.faqCard {
  background: #222; /* Dark card */
  border-radius: 8px;
  overflow: hidden;
  transition: 0.3s;
}
.span_box {
  width: 100%;
  height: 1px; /* Border thickness */
  background: linear-gradient(
    90deg,
    rgba(0, 147, 229, 0) 0%,
    #004ee5 16.67%,
    rgba(0, 119, 229, 0) 100%
  );
}
.faqColumnBox {
  display: flex;
  flex-direction: column;
}

/* Accordion Header */
.faqHeader {
  background: #181818;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  transition: 0.3s;
}

.faqHeader:hover {
  background: #292929;
}

/* Number Box */
.faqId {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  margin-right: 10px;
}

/* Question Text */
.faqQuestion {
  flex-grow: 1;
  font-size: 16px;
}

/* Toggle + / - Button */
.faqToggle {
  font-size: 20px;
  font-weight: bold;
  color: white;
  transition: 0.3s;
}

/* Accordion Body (Hidden by default) */
.faqBody {
  background: #1a1a1a;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

/* Expanded State */
.faqBody.open {
  max-height: 200px; /* Adjust based on content size */
  opacity: 1;
}

/* Inner Content */
.faqBodyContent {
  padding: 15px;
  color: white;
  font-size: 14px;
  line-height: 1.5;
}

@media screen and (min-width: 230px) and (max-width: 460px) {
  .faqGrid {
    display: flex;
    flex-direction: column;
  }
}
