.profile_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: inherit;
}

.profile_container_wallet {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: var(--background-color-5);
  padding: 5rem;
  gap: 2rem;
}

.profile_container_wallet form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.profile_container_wallet_header {
  display: flex;
  flex-direction: column;
}

.profile_container_wallet_header label {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profile_container_wallet_header h4 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profile_container_wallet_body {
  display: flex;
  flex-direction: column;
}
.profile_container_wallet_body_top {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  gap: 20px;
}
.profile_container_wallet_body_top input {
  border-radius: 15px;
  background: var(--background-color-6);
  color: var(--background-color-1);
  font-size: 20px;
  padding: 10px;
  font-style: normal;
  font-weight: 600;
  border: none;
  line-height: normal;
  outline: none;
  text-align: center;
  width: fit-content;
}

.profile_container_wallet_body_top p {
  color: var(--text-color-6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile_container_wallet_body_bottom {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.profile_container_wallet_body_bottom_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.profile_container_wallet_body_bottom_btn button {
  border-radius: 15px;
  border: 1px solid var(--white-color);
  background: transparent;
  padding: 0.4rem 2rem;
  color: var(--white-color);
  font-weight: 600;
}

.profile_container_wallet_body_bottom_btn label {
  color: var(--text-color-6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile_container_wallet_body_bottom_btn button:hover {
  background: var(--background-color-6);
  color: var(--background-color-1);
  font-style: normal;
  font-weight: 600;
}

.profile_container_wallet_btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_container_wallet_btns button {
  border-radius: 15px;
  background: var(--btn-color-1);
  border: none;
  width: 90%;
  height: 3.5rem;
  /* display: flex; */
  color: var(--white-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  align-items: center;
  text-align: center;
  /* padding: 0.1rem 3rem; */
}

.profile_container_details {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: var(--background-color-5);
  padding: 3rem;
  gap: 2rem;
}

.profile_container_details_top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.profile_container_details_top h4 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profile_container_details_top_info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile_container_details_top_info label {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  > span {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.profile_container_details_bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile_container_details_bottom h4 {
  font-size: 26px;
}

.profile_container_details_bottom_box label {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.helper_text p {
  color: var(--text-color-6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile_container_details_bottom_btns {
  display: flex;
  justify-content: flex-end;
}
.profile_container_details_bottom_btns button {
  border-radius: 15px;
  background: var(--background-color-8);
  border: solid 1px var(--text-color-7);
  /* width: 90%; */
  height: 2.5rem;
  /* display: flex; */
  color: var(--text-color-7);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  align-items: center;
  text-align: center;
  /* padding: 0.1rem 3rem; */
}

.profile_container_details_bottom_btns button:hover {
  background: var(--text-color-7);
  color: var(--white-color);
  border: solid 1px var(--text-color-7);
}

.profile_container_wallet_body_bottom_btn_button_selected {
  background: var(--background-color-6) !important;
  color: var(--background-color-1) !important;
  font-style: normal;
  font-weight: 600;
}

@media screen and (min-width: 461px) and (max-width: 1200px) {
  .profile_container_wallet {
    padding: 3rem;
    gap: 1rem;
  }
  .profile_container_details {
    padding: 3rem;
    gap: 1rem;
  }
}

@media screen and (min-width: 230px) and (max-width: 460px) {
  .profile_container_wallet {
    padding: 1.5rem;
    gap: 0.4rem;
  }
  .profile_container_details {
    padding: 2rem;
    gap: 0.4rem;
  }

  .profile_container {
    flex-direction: column-reverse;
    gap: 10px;
  }

  .profile_container_wallet_header label {
    font-size: 16px;
  }
  .profile_container_wallet_header h4 {
    font-size: 30px;
  }

  .profile_container_wallet_body_bottom {
    gap: 5px;
  }
  .profile_container_wallet_body_bottom_btn {
    gap: 5px;
  }
  .profile_container_wallet_body_bottom_btn button {
    padding: 0.4rem 1rem;
  }
}
