.carousel_container_box {
  /* width: 100%; */
  /* height: 84vh; */
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.carousel_container {
  /* width: 100%; */
  /* height: 100%; */
}

.banner_box {
  width: 100%;
  height: 84vh;
}

.banner_images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid #262626;
}
.banner_footer {
  /* height: 220px; */
}

.banner_title {
  color: var(--text-color-5);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.15px;
}

.banner_synopsis {
  color: var(--text-color-6);
  font-size: 16px;
  max-width: 80%;
  margin: 0 auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (min-width: 230px) and (max-width: 460px) {
  .carousel_container_box {
    max-height: 200px;
  }
  .banner_box {
    width: 100%;
    max-height: 200px;
  }
  .banner_footer {
    /* height: 120px; */
  }

  .banner_title {
    font-size: 20px;
  }

  .banner_synopsis {
    font-size: 12px;
  }
}
