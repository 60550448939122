.signup_container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  /* padding: 10px 0px; */
}

.signup_container_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 25rem;
  /* height: 50%; */
  /* padding: 2rem 3rem; */
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #313a3f; /* Light blue border */
  background: rgb(2, 0, 36);
  background: linear-gradient(235deg, #121516 25%, #313a3f 60%, #455155 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 8px 12px rgba(0, 0, 0, 0.15); /* Shadow effect */
  background-blend-mode: overlay, normal; /* Blends background image with gradient */
  backdrop-filter: blur(40px); /* Apply blur effect behind */
}
.signup_box {
  width: auto;
  /* height: 500px; */
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  gap: 15px;
}

.signup_box_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.signup_box_header label {
  font-size: 18px;
  /* padding: 2% 10%; */

  text-transform: uppercase;
  color: #fff;
  font-family: "BebasNeue-Regular";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
}

.signup_box_header svg {
  width: 130px;
  height: 42px;
  flex-shrink: 0;
}

.signup_box_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.signup_box_body_text {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2% 10%;
}
.signup_box_body_text label {
  display: inline;
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signup_box_body_text label span:nth-child(1) {
  color: var(--text-color-2);
  /* text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row; */
}
.signup_box_body_text label span:nth-child(2) {
  color: var(--text-color-2);
}

.signup_box_body_form {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 90%; */
}
.signup_box_body_form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  /* width: 100%; */
  /* padding: 2px; */
  /* overflow: hidden; */
}

.signup_box_body_form_recaptcha {
  display: "flex";
  justify-content: "center";
  margin: "auto";
  transform: "scale(0.2)" !important;
  transform-origin: "center";
  /* padding: 3px 20px;
  overflow: hidden; */
  min-height: 78px;
  min-width: 304px;
}

.err_message_pass {
  height: 18px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0px;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  font-size: 14px;
  margin: 0px;
}

.signup_form_btn {
  display: flex;
  width: 100%;
}

.signup_box_body_form form input {
  width: 100%;
  display: flex;
  padding: 14px 10px;
  border-radius: 10px;
  border: 1px solid transparent;
  outline: none; /* Removes focus border (if needed) */
  color: #1a1a1a;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.signup_box_body_form form input:focus {
  border: 1px solid var(--btn-color-1);
}

.signup_form_btn button {
  display: flex;
  justify-content: center;
  padding: 12px 10px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  background-color: var(--text-color-2);
  border-color: var(--text-color-2);
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}

.signup_form_btn button span {
  max-width: 24px;
  max-height: 24px;
}

.signup_form_btn button:hover {
  background-color: var(--btn-hover-color);
}

.signup_box_footer {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.signup_box_footer label {
  font-size: 12px;
  align-items: center;
  justify-content: center;
}
.signup_box_footer label span:nth-child(1) {
  color: var(--text-color-2);
}

.signup_box_footer label span:nth-child(2) {
  color: var(--text-color-2);
}
