/* Laptop Mode or TV */

.laptopCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: #1e1e1e; */
  /* background-color: rgba(39, 36, 36, 0.507); */
  /* Light transparent background */
  width: 100%;

  color: var(--white-color);
  cursor: pointer;

  border-radius: 12px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 2rem;
}
/* .laptopCard:hover {
  
      background: var(--bg-color-2);
    
  } */

.laptopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 80%; */
  gap: 0.2rem;
}

.laptopImageContainer {
  position: relative;
  flex-shrink: 0;
}

.laptopImage {
  display: flex;
  width: 300px;
  height: 202px;
  border-radius: 8px;
}

.laptopImageImg {
  width: 300px;
  height: 202px;
  object-fit: cover;
  border-radius: 8px;
}
/*   
  .laptopPlayButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

/* .laptopPlayIcon {
    color:var(--white-color);
    font-size: 20px;
  } */

.laptopContent {
  /* margin-left: 16px; */
  padding: 16px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.laptopEpisodeLabel {
  font-weight: bold;
  /* font-size: 18px; */
  color: var(--text-color-5);
}

.laptopCastAndCrew {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* width: 100%; */
}
.laptopCastBadge {
  display: flex; /* Enable flexbox */
  flex-direction: row; /* Arrange items in a row */
  align-items: center; /* Vertically center the items */
  gap: 5px; /* Space between elements */
  flex-wrap: wrap;
  color: var(--text-color-6);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.laptopCastBadgeWrap {
  display: flex;
  white-space: inherit !important;
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 0.3rem 0.5rem;
  width: fit-content;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  color: var(--text-color-5);
}

.laptopInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  color: var(--text-color-6);
  font-size: 16px;
}

.laptopDate {
  color: var(--white-color);
}

.laptopDuration {
  color: var(--white-color);
}

.laptopIcon {
  background-color: #333;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}
.laptopDescriptionWrapper {
  position: relative;
  color: var(--text-color-6);

  .toggleButton {
    color: #007bff;
    /* background-color: #ffc107; */
    cursor: pointer;
    font-weight: 400;
  }
}

.laptopDescription {
  margin-top: 12px;
  color: #bbb;
  line-height: 1.5; /* Line height */
  font-size: 16px;
  text-align: initial;
  overflow: hidden; /* Required to clip the content */
  display: -webkit-box; /* Use a flex-like box model */
  -webkit-box-orient: vertical; /* Specify vertical layout */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  word-wrap: break-word; /* Break long words if necessary */
}

.viewMoreButton {
  display: none; /* Initially hidden */
  margin-top: 8px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.viewMoreButton.visible {
  display: inline-block; /* Show button when needed */
}

.laptopFreeEpisode {
  margin-top: 12px;
  color: #007bff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

/* Mobile Mode */

.mobileCard {
  background-color: var(--bg-color-2);
  border-radius: 8px;
  padding: 10px;
  /* max-width: 400px; */
  color: #fff;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  border-radius: 12px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 2rem;
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileTitleWrapper {
  display: flex;
  align-items: center;
}

.mobileEpisodeLabel {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}

.mobileArrow {
  font-size: 20px;
  color: #aaa;
}

.mobileExpanded {
  /* padding: 16px; */
  cursor: default;
}

.mobileDetails {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* display: flex;
    flex-direction: row; */
}

.mobileImageContainer {
  position: relative;
  /* max-width: 50%; */
  height: 100px;
  width: 100px;
}

.mobileImage {
  /* width: 100%; */
  /* height: auto; */
  width: 100px;
  height: 110px;
  border-radius: 8px;
  object-fit: cover;
}

/* .mobilePlayButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

/* .mobilePlayIcon {
    color: #fff;
    font-size: 20px;
  } */

.mobileContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  /* justify-content: space-between; */
}

.mobileInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  /* margin-top: 8px; */
  color: #aaa;
  font-size: 14px;
}

.mobileDate {
  color: #aaa;
}

.mobileDuration {
  color: #aaa;
}

.mobileIcon {
  background-color: #333;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.mobileDescription {
  margin-top: 12px;
  color: #bbb;
  line-height: 1.5;
  font-size: 14px;
  white-space: normal; /* Allows text wrapping */

  .toggleButton {
    color: #007bff;
    /* background-color: #ffc107; */
    cursor: pointer;
    font-weight: bold;
  }
}

.mobileFreeEpisode {
  margin-top: 12px;
  color: #007bff;
  font-weight: bold;
  font-size: 14px;
}

.mobilePrimeEpisode {
  margin-top: 8px;
  color: #ffc107;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
}

/* Tablet Mode */

.tabletCard {
  background-color: var(--bg-color-2);
  border-radius: 8px;
  padding: 10px;
  /* max-width: 400px; */
  color: #fff;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  border-radius: 12px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 2rem;
}

.tabletHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabletTitleWrapper {
  display: flex;
  align-items: center;
}

.tabletEpisodeLabel {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}

.tabletArrow {
  font-size: 20px;
  color: #aaa;
}

.tabletExpanded {
  /* padding: 16px; */
  cursor: default;
}

.tabletDetails {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 10px;
  /* display: flex;
    flex-direction: row; */
}

.tabletImageContainer {
  position: relative;
  /* min-width: 35%; */
}

.tabletImage {
  width: 150px;
  height: 180px;
  border-radius: 8px;
  object-fit: cover;
}

/* .tabletPlayButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

/* .tabletPlayIcon {
    color: #fff;
    font-size: 20px;
  } */

.tabletContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  /* justify-content: space-between; */
}

.tabletInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  /* margin-top: 8px; */
  color: #aaa;
  font-size: 14px;
}

.tabletContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tabletDate {
  color: #aaa;
}

.tabletDuration {
  color: #aaa;
}

.tabletIcon {
  background-color: #333;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.tabletDescription {
  margin-top: 12px;
  color: #bbb;
  line-height: 1.5;
  font-size: 14px;
}

.tabletFreeEpisode {
  margin-top: 12px;
  color: #007bff;
  font-weight: bold;
  font-size: 14px;
}

.tabletPrimeEpisode {
  margin-top: 8px;
  color: #ffc107;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
}

@media screen and (min-width: 270px) and (max-width: 620px) {
  .container {
    gap: 5px;
  }
}
