.disabled {
  color: var(--text-color-6) !important;
  background-color: transparent !important;
  opacity: 0.5;
  cursor: not-allowed;
  /* cursor: crosshair; */
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled:hover {
  color: var(--text-color-6) !important;
  background-color: transparent !important;
}

.resumePlayViaAds_modal :global(.modal-dialog) {
  /* max-width: 60%; */
  /* width: 60%; */
  height: auto;
}

.resumePlayViaAds_modal :global(.modal-content) {
  background-color: #121212; /* Dark background */
  color: white; /* White text */
  border-radius: 10px;
  padding: 20px;
}

.resumePlayViaAds_modal :global(.modal-header) {
  display: block;
}

.resumePlayViaAds_modal_btn {
  border-radius: 6px;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--btn-color-1);
  background: rgba(82, 229, 194, 0);

  color: var(--btn-color-1);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
