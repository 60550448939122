.header_container {
  display: flex;
  /* padding: 1rem 4rem;
  justify-content: space-between;
  align-items: center; */
}

.header_container_topnavbar {
  display: flex;
  width: 100%;
}

.header_container_sidebar {
  display: none;
  width: 100%;
}

@media screen and (min-width: 230px) and (max-width: 1300px) {
  .header_container_topnavbar {
    display: none;
  }
  .header_container_sidebar {
    display: flex;
  }
}
