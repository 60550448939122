.Auth_Layout_container {
  display: flex;
  flex-direction: column;
}
.Auth_Layout_container_outlet {
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
