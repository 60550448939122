.group_purchase_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.group_purchase_container_details {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 3rem;
  gap: 3rem;
  background: var(--background-color-7);
}

.group_purchase_container_details_top {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
.group_purchase_container_details_top_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.group_purchase_container_details_top_left img {
  max-width: 200px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 12px;
}

.group_purchase_container_details_top_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 80%;
  gap: 0.8rem;
}

.group_purchase_container_details_top_right div:nth-child(1) h4 {
  color: var(--text-color-5);

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

.group_purchase_container_details_top_right_duration {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.group_purchase_container_details_top_right_duration div {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  padding: 3px 10px;
  border: 1px solid var(--background-color-8);
}
.group_purchase_container_details_top_right_description p {
  color: var(--white-color);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group_purchase_container_details_top_right_policy {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.group_purchase_container_details_top_right_policy p {
  margin: 0px;
}

.group_purchase_container_details_top_right_policy img {
  width: 32px;
  height: 30px;
  padding: 3px;
  flex-shrink: 0;
  background-color: var(--text-color-2);
  border-radius: 50%;
}

.group_purchase_container_details_bottom {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.group_purchase_container_details_bottom_top div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.group_purchase_container_details_bottom_top div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.group_purchase_container_details_bottom_top div button:nth-child(1) {
  border-radius: 8px;
  background: var(--btn-color-1);
  border: none;
  display: flex;
  flex-direction: column;
  width: auto;
  height: 3.4rem;
  padding: 0px 10px;
  display: flex;
  color: var(--white-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  line-height: normal;
  align-items: center;
}

.group_purchase_container_details_bottom_top div button:nth-child(2) {
  border-radius: 8px;
  background: transparent;
  display: flex;
  flex-direction: column;
  border: none;
  width: auto;
  height: 3.4rem;
  padding: 0px 10px;
  border: solid 1px var(--btn-color-1);
  display: flex;
  color: var(--white-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  line-height: normal;
  align-items: center;
}

.group_purchase_container_details_bottom_bottom p {
  color: var(--text-color-6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (min-width: 1001px) and (max-width: 1400px) {
  .group_purchase_container_details_top_right {
    width: 60%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .group_purchase_container_details_top_right {
    width: 98%;
  }
  .group_purchase_container_details_top {
    gap: 2rem;
  }
  .group_purchase_container_details {
    padding: 2rem;
  }
}

@media screen and (min-width: 461px) and (max-width: 767px) {
  .group_purchase_container_details_top {
    gap: 1rem;
    flex-direction: column;
  }
  .group_purchase_container_details_top_right {
    width: 98%;
  }
}

@media screen and (min-width: 230px) and (max-width: 460px) {
  .group_purchase_container_details_top {
    gap: 10px;
    flex-direction: column;
  }
  .group_purchase_container_details_top_right {
    width: 99%;
  }
  .group_purchase_container_details {
    padding: 15px;
  }
  .group_purchase_container_details_top_right_duration {
    flex-direction: column;
    align-items: flex-start;
  }
  .group_purchase_container_details_top_right_duration svg {
    display: none;
  }
}
