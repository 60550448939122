.carousel_item {
  /* background: var(--bg-color-1); */
  margin: 0.5rem;
  /* transition: 500ms ease-in; */
  transition: transform 0.3s ease-in-out, z-index 0s;
  color: var(--white-color);
}
.carousel_item:hover {
  transform: scale(1.1);
  z-index: 9999;
}
.poster_container {
  background-color: rgba(0, 0, 0, 0.1);
  height: 140px;
  /* width: 225px; */
  overflow: hidden;
}
.carousel_item {
  cursor: pointer;
  position: relative;
}
.poster_container img {
  width: 100%;
  /* width: 225px; */
  border-radius: 5px;
  height: 140px;
  object-fit: cover;
}
.carousel_item_chip {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.carousel_item_chip label {
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  background-color: #017bf4;
  border-radius: 15px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  /* color: #017bf4; */
  align-items: center;
  justify-content: center;
}

/* media queries for mobile screen */
@media screen and (min-width: 270px) and (max-width: 480px) {
  .poster_container {
    height: 180px;
    width: 100%;
  }
  .poster_container img {
    height: 180px;
    width: 100%;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
  .poster_container {
    height: 160px;
  }
  .poster_container img {
    height: 160px;
  }
}

@media screen and (min-width: 621px) and (max-width: 867px) {
  .poster_container {
    /* max-width: 400px; */
  }
  .poster_container img {
  }
}

@media screen and (min-width: 867px) {
  .carousel_item {
    max-width: 250px;
  }
  .poster_container {
    max-width: 250px;
  }
  .poster_container img {
    max-width: 250px;
  }
}
