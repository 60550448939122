.rating_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 4rem;
  gap: 1rem;
  width: 60%;
}
.rating_container > h2 {
  text-align: center;
}
/* .rating_container > h3 {
    width: 50%;
    margin: auto;
    text-align: center;
  } */

.rating_container > form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: inherit;
  gap: 1rem;
}
.rating_container > form > textarea {
  width: 100%;
  background-color: var(--input-color);
  border: none;
  color: var(--white-color);
  font-size: 1rem;
  padding: 6px;
}
.rating_container > form > textarea:focus {
  background-color: #3e404a; /* Change background color on focus */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.164); /* Add a subtle box shadow on focus */
  outline: none; /* Remove the default focus outline */
}
.follow_btn {
  border-radius: 15px;
  background: var(--btn-color-1);
  border: none;
  width: 70%;
  height: 3.5rem;
  display: flex;
  color: var(--white-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  align-items: center;
}

.follow_btn:hover {
  color: var(--white-color);
  border: 1px solid;
  transition: 200ms ease-in;
}
.follow_btn:active {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
}

@media screen and (min-width: 270px) and (max-width: 480px) {
  .rating_container {
    width: 90%;
  }
  .star_container > svg {
    width: 3rem;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
  .rating_container {
    width: 80%;
  }
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
