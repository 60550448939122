.media_frame {
  max-height: 100vh;
  /* padding-top: 1rem; */
}
.media_frame > video {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 2/1;
}
.media_frame > iframe {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 2/1;
}

.media_frame_loader {
  display: flex;
  object-fit: cover;
  justify-content: center;
  justify-items: center;
  align-items: center;
  min-height: 80vh;
}

.media_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}
.media_container > div {
  flex: 1;
}
.episodes_list > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  color: var(--white-color);
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-11);
  padding: 2rem;
  gap: 1rem;
}
.episodes_list > div img {
  height: 140px;
}

.episodes_list > div {
  transition: 500ms ease-in-out;
}
.episodes_list > div:hover {
  box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px,
    rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;
}
.selected_episode {
  background-color: var(--bg-color-3);
  box-shadow: rgba(255, 255, 255, 0.233) 0px 6px 24px 0px,
    rgba(255, 255, 255, 0.233) 0px 0px 0px 1px !important;
}
.episodes_details_header {
  display: flex;
  gap: 1rem;
  color: var(--text-color-2);
}

.episode_title {
  margin-top: 12px;
  color: #fff; /* Adjust color as needed */
  font-size: 18px; /* Adjust font size as needed */
  text-align: initial;
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis at the end of the line */
  word-wrap: break-word; /* Break long words if necessary */
}

.episodes_poster {
  /* height: 100px; */
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.episodes_poster img {
  height: auto;
  width: 200px;
  object-fit: cover;
}

.episode_synopsis {
  /* margin-top: 12px; */
  color: #bbb;
  line-height: 1.5; /* Line height */
  font-size: 16px;
  text-align: initial;
  display: -webkit-box; /* Required for multi-line ellipsis */
  -webkit-box-orient: vertical; /* Set orientation to vertical */
  -webkit-line-clamp: 2; /* Limit text to 2 lines */
  overflow: hidden; /* Hide overflow */
  word-wrap: break-word; /* Break long words */
}

.episodes_list {
  display: flex;
  flex-direction: column;
  /* max-height: 100vh;
  overflow-y: scroll; */
  gap: 1rem;
}
.episodes_list::-webkit-scrollbar {
  background: transparent;
}
.player_btn {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
  background: transparent;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  padding: 0.3rem 1rem;
}

.player_btn:hover {
  color: var(--white-color);
  border: 1px solid;
  transition: 200ms ease-in;
}
.player_btn:active {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
}
.player_btn_container {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.audio_screen {
  width: 100%;
  padding: 1rem;
  display: flex;
  background-image: url(../../assets/images/background.png);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 1rem;
  background-position: right;
  background-repeat: no-repeat;
}

.audio_screen > img {
  object-fit: contain;
  width: 80%;
  margin: auto;
  max-height: 300px;
}
.bluetooth_status {
  color: #f8d64e;
}
.video_player_container {
  position: relative;
}
.progress_container {
  height: 1rem;
  width: 100%;
  background-color: var(--nav-color);
  display: flex;
}
.progress_bar {
  background-color: var(--text-color-2);
  cursor: pointer;
  width: 1%;
  height: 1rem;
}
.range_button:hover {
  transform: scale(1.4);
}
.timer_container {
  padding-left: 1rem;
}
.range_button {
  width: 1rem;
  cursor: pointer;
  transition: 400ms ease-in-out;
  transform: translate(-0.5rem);
  background: var(--text-color-2);
  border: 5px solid #fff;
  border-radius: 100%;
}
.icon_controles > svg {
  cursor: pointer;
}
.icon_controles {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 2rem;
}

.report_btn_container > button:hover > svg path {
  fill: var(--white-color);
}

.report_btn_container > button:active {
  color: var(--text-color-2);
  border: 2px solid var(--text-color-2);
}
.report_btn_container > button:active > svg path {
  fill: var(--text-color-2);
}

.report_btn_container {
  display: inline-flex;
  gap: 1rem;
  align-items: flex-start;
}
.report_btn_container > button:hover {
  color: var(--white-color);
  border: 2px solid var(--white-color);
}
.report_btn_container > button {
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center !important;
  justify-content: center;
}
.report_btn_container > button > svg path {
  fill: var(--text-color-2);
  transition: 200ms ease-in;
}
.report_btn_container > button > svg {
  width: 2rem;
}
.report_btn_container > button {
  /* margin-top: 1rem; */
  display: flex;
  border: 2px solid var(--text-color-2);
  font-weight: 500;
  font-size: 1.3rem;
  width: fit-content;
  color: var(--text-color-2);
  align-items: flex-start;
  background-color: transparent;
  border-radius: 1rem;
  transition: 200ms ease-in;
}
.report_btn_container_mobile {
  display: none;
}

.flag_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.4rem;
}
.capitalize::first-letter {
  text-transform: uppercase !important;
}
.price_modal_container {
  background: var(--background-color-7);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  border-bottom: 0px;
  border-radius: 5px;
  border: 1px solid var(--Black-15, #262626);
  background: var(--background-color-7);
}
.report_btn {
  padding: 0.5rem !important;
  margin: auto;
}

.watch_trailer_btn {
  display: inline-flex;
  gap: 1rem;
  align-items: flex-start;
}
.watch_trailer_btn > button {
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center !important;
  justify-content: center;
}
.watch_trailer_btn > button > svg path {
  fill: var(--text-color-2);
  transition: 200ms ease-in;
}
.watch_trailer_btn > button > svg {
  width: 2rem;
}
.watch_trailer_btn > button {
  margin-top: 1rem;
  display: flex;
  border: 2px solid var(--text-color-2);
  font-weight: 500;
  font-size: 1.3rem;
  width: fit-content;
  color: var(--text-color-2);
  align-items: flex-start;
  background-color: transparent;
  border-radius: 1rem;
  transition: 200ms ease-in;
}

.trailers_btns {
  display: flex;
  background: var(--bg-color-2);
  border-top: none;
}
.trailers_btns > button {
  margin-top: 0rem !important;
}
.watch_trailer_btn > button:hover {
  color: var(--white-color);
  border: 2px solid var(--white-color);
}
.disabled_btn {
  border: 2px solid var(--disabled-btn) !important;
  color: var(--disabled-btn) !important;
  cursor: not-allowed !important;
}
.watch_trailer_btn > button:hover > svg path {
  fill: var(--white-color);
}

.watch_trailer_btn > button:active {
  color: var(--text-color-2);
  border: 2px solid var(--text-color-2);
}
.custom_controller_box {
  position: relative;
}
.controls_box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
.controls {
  display: flex;
  gap: 4rem;
  align-items: center;
}
.controls > div {
  /* border: 1px solid green; */
  width: 10rem;
  cursor: pointer;
}
.backward {
  z-index: 1;
}

@media screen and (min-width: 270px) and (max-width: 480px) {
  .media_container {
    flex-direction: column;
    position: relative;
  }
  .media_container > div:nth-child(1) {
    /* position: sticky;
    top: 0;
    background-color: black; */
  }
  .episodes_list img {
    height: 60px !important;
  }
  .episodes_list h3 {
    font-size: 1rem;
  }
  .episodes_list {
    font-size: 0.5rem !important;
  }
  .controls_box {
    width: 50%;
  }
  .controls > div {
    /* border: 1px solid green; */
    width: 3rem;
    cursor: pointer;
  }
  .media_frame_loader {
    height: 100vh;
  }
  .episodes_poster {
    /* height: 100px; */
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .episodes_poster img {
    /* height: 100px; */
    width: 100px;
  }
  .report_btn_container_mobile {
    display: flex;
  }
  .report_btn_container_laptop {
    display: none;
  }

  .episodes_list > div {
    padding: 0.4rem;
    gap: 1rem;
  }

  .episodes_details_header {
    font-size: 14px;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
  .media_container {
    flex-direction: column;
    position: relative;
  }
  .media_container > div:nth-child(1) {
    /* position: sticky;
    top: 0; */
  }
  .media_frame > iframe {
    height: fit-content;
  }
  .media_frame_loader {
    height: 230px;
  }
}

@media screen and (min-width: 480px) and (max-width: 867px) {
  .media_container {
    flex-direction: column;
    position: relative;
    font-size: 1rem;
  }
  .media_container > div:nth-child(1) {
    /* position: sticky;
    top: 0;
    background-color: black; */
  }
  .media_frame > iframe {
    height: fit-content;
  }
  .media_frame_loader {
    height: 300px;
  }
  .report_btn_container_mobile {
    display: flex;
  }
  .report_btn_container_laptop {
    display: none;
  }
}

@media screen and (min-width: 868px) {
  .report_btn_container_mobile {
    display: none;
  }
  .report_btn_container_laptop {
    display: flex;
  }
}
