.transaction_history_container {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
}

.transaction_history_container_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.transaction_history_container_header h5 {
  color: var(--white-color);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.transaction_history_container_header label {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.transaction_history_container_body {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.transaction_history_container_body_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 30px;
  border: 1px solid var(--white-color);
  padding: 0.5rem 2rem;
}

.transaction_history_container_body_header label {
  color: var(--white-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.transaction_history_container_body_not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.transaction_history_container_body_not_found img {
  max-width: 200px;
}
.transaction_history_container_body_container_list {
  /* border-radius: 20px; */
  border: none;
  gap: 20px;
}
.transaction_history_container_body_container_list_item {
  background-color: transparent;
  border-radius: 20px !important;
  border-width: 1px !important;
}
.list_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem;
  flex: 1, 1, 1;
}

.list_item div img {
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--white-color);
  height: 60px;
  width: 80px;
  padding: 10px;
  object-fit: cover;
  flex: 1;
}

.list_item div:nth-child(2) h5 {
  color: var(--white-color);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.list_item div:nth-child(2) label {
  color: var(--text-color-7);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.list_item div:nth-child(3) label {
  color: var(--white-color);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (min-width: 230px) and (max-width: 460px) {
  .transaction_history_container {
    padding: 0.8rem;
  }
  .transaction_history_container_body {
    padding: 0rem;
  }
  .list_item div img {
    height: 50px;
    width: 60px;
    padding: 4px;
    object-fit: cover;
  }
  .list_item div:nth-child(2) h5 {
    font-size: 14px;
  }
  .list_item div:nth-child(2) label {
    font-size: 10px;
  }
  .list_item div:nth-child(3) label {
    font-size: 12px;
  }
}
