.watch_history_container {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}
.watch_history_container h5 {
  padding: 1rem;
}
.watch_history_container_box {
  /* min-height: 50vh; */
  display: flex;
  justify-content: center;
}

.custome_center {
  align-items: center;
}
