.header_text {
  text-decoration: underline;
  font-weight: 500;
  text-align: center;
}

.privacy_container {
  background-color: var(--background-color-7);
  color: var(--white-color);
  padding: 30px;
}

.privacy_container li {
  margin-bottom: 10px;
}
