.topNavBar_container {
  display: flex;
  /* padding: 1rem 4rem; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.topNavBar_container_logo {
}

.topNavBar_container_slugs {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 2px solid var(--bg-border-grey);
  background: var(--background-color-3);

  display: flex;
  padding: 5px 10px;
  align-items: center;
}

.topNavBar_container_slugs_box {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.topNavBar_container_slugs_box span {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
  border: 1px solid transparent;
}

.topNavBar_container_slugs_selected_item {
  border: 1px solid var(--background-color-1);
  background: var(--text-color-2);
}

.topNavBar_container_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-end;
  gap: 30px;
}

.topNavBar_container_menu_input form {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.search-input {
  width: 0;
  opacity: 0;
  padding: 0;
  border: none;
  outline: none;
  transition: all 0.5s ease-in-out;
}

.search-input.active {
  width: 200px; /* Adjust as needed */
  opacity: 1;
  border-radius: 10px;
  padding: 8px;
  border: 1px solid var(--text-color-4);
}

.topNavBar_container_menu_icon_dropdow_item {
  padding: 1rem 2rem;
  display: flex;
  align-items: flex-start;
  text-align: start;
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  /* letter-spacing: 0.15px; */
  border-radius: 20px;
}
.topNavBar_container_menu_icon_dropdow_item a:hover {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
