.invite_users_container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
}

.invite_users_container_header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.invite_users_container_header svg {
  height: 45px;
  width: 45px;
}

.invite_users_container_header h4 {
  font-size: xx-large;
}

.invite_users_container_body {
  padding: 3rem;
  background: var(--background-color-10);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.invite_users_container_body_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.invite_users_container_body_box_left {
  display: flex;
  flex-direction: row;
  width: 60%;
}
.invite_users_container_body_box_left div {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--bg-color-3);
  padding: 0.5rem 1rem;
  color: var(--white-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.invite_users_container_body_box_left div input {
  background: var(--bg-color-3);
  border: none;
  color: var(--white-color);
  font-size: 1rem;
  padding: 0.7rem;
  border: none;
  outline: none;
  display: flex;
  width: 100%;
}

.invite_users_container_body_box_left div button {
  display: flex;
  border-radius: 15px;
  background: var(--btn-color-1);
  border: none;
  width: 20%;
  height: 2.5rem;
  display: flex;
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  align-items: center;
}

.invite_users_container_body_box_right {
  display: flex;
  flex-direction: row;
}

.invite_users_container_body_box_right div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.invite_users_container_body_box_right div div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
}

.invite_users_container_body_box_right div div svg {
  height: 30px;
  width: 30px;
}

.invite_users_container_steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.invite_users_container_steps_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.invite_users_container_steps_box svg {
  width: 20px;
  height: 20px;
}

.invite_users_container_steps_box div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: var(--background-color-10);
  border-radius: 15px;
  padding: 0.3rem 1rem;
}

.invite_users_container_steps_box div div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
}

.invite_users_container_steps_box div svg {
  height: 35px;
  width: 35px;
}

.invite_users_container_body_box_left_div {
  position: relative;
}

.invite_users_container_body_box_left_div_tooltip {
  color: black;
  position: absolute;
  bottom: 0;
  right: 50px;
  padding: 0px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 1%;
  background-color: rgb(28, 195, 61);
}

.invite_users_container_steps_box_right_arrow {
  display: block;
}
.invite_users_container_steps_box_down_arrow {
  display: none;
}

.boom_animation div div:nth-child(1) {
  background: var(--background-color-4);
  /* border: 1px solid var(--background-color-8); */
}

.boom_animation_div {
  display: flex;
  flex-direction: column;
}

.boom_animation_div span {
  color: red;
}

.boom_animation_div div input {
  background: var(--bg-color-3);
  border: none;
  color: var(--white-color);
  font-size: 1rem;
  padding: 0.7rem;
  border: none;
  outline: none;
  display: flex;
  width: 100%;
  border-radius: 15px;
}

.boom_animation div div input:focus {
  background: var(--bg-color-3);
  border: none;
  color: var(--white-color);
  border-color: var(--white-color) !important;
  outline: none !important;
}
.remove_button svg {
  color: red;
}

.invite_users_container_invited_box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.invite_users_container_invited_box h4 {
  font-size: xx-large;
}

.invite_users_container_invited_box_body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.invite_users_container_invited_box_body div {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  gap: 0.5rem;
}

.invite_users_container_invited_box_body_user {
  padding: 2rem;
  background: var(--background-color-10);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.boom_animation:global(.modal-header) {
  background: var(--background-color-4);
  border: none;
  border-radius: 15px;
  color: red;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #444;
  padding: 15px;
}

.closeIcon {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

@media screen and (min-width: 261px) and (max-width: 1024px) {
  .invite_users_container_body_box {
    flex-direction: column;
    gap: 20px;
  }

  .invite_users_container_steps_box_right_arrow {
    display: none;
  }
  .invite_users_container_steps_box_down_arrow {
    display: block;
  }
  .invite_users_container_body_box_left {
    width: 100%;
  }
  .invite_users_container_body_box_left div button {
    width: 30%;
  }

  .invite_users_container_steps_box {
    flex-direction: column;
  }

  .invite_users_container_steps_box div {
    width: 100%;
  }
  .invite_users_container_body {
    gap: 1rem;
    padding: 20px;
  }

  .invite_users_container {
    padding: 1rem;
  }
}
