.banner_containr {
  border-radius: 12px;
  border: 1px solid #262626;
  height: 85vh;
  flex-shrink: 0;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.banner_containr_box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.banner_containr_box_title h3 {
  color: var(--text-color-5);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

.banner_containr_box_body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.banner_containr_box_body_tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.banner_containr_box_body_tags label {
  color: var(--white-color);

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.banner_containr_box_body_tags_cbfc {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  padding: 0.2rem 0.4rem;
  border: 1px solid var(--background-color-8);
}

.banner_containr_box_body_tags_add_watchList {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  padding: 5px;
  border: solid 1px transparent;
}

.banner_containr_box_body_tags_add_watchList:hover svg {
  border-color: var(--success-color);
}

.banner_containr_box_body_tags_add_watchList_fill {
  background-color: var(--success-color);
}
.banner_containr_box_body_info {
  width: 50%;
}

.banner_containr_box_body_info p {
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4; /* Adjust for better spacing */
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 3; /* Restrict to 3 lines */
  -webkit-box-orient: vertical;

  max-height: 4.2em; /* Adjust according to line-height (1.4 * 3) */
  word-wrap: break-word; /* Ensures words break properly */
}

.banner_containr_box_btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.tickets {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid var(--btn-color-1);
  background: rgba(217, 217, 217, 0);
  justify-content: center;
  align-items: center;
}

.tickets h5 {
  color: var(--btn-color-1);
  font-style: normal;
  font-weight: 700;
  /* line-height: 102.1%; */
  padding: 2px;
}

.tickets_body {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--btn-color-1);
  justify-content: space-between;
}

.tickets_body_left {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: flex-start;

  padding-right: 20px;
  background-color: transparent;
  border: none;
  color: var(--white-color);
}

.tickets_body_left_header label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.1%; /* 28.42px */
}

.tickets_body_right_header label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.1%; /* 28.42px */
}

.tickets_body label span {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 102.1%; /* 24.504px */
}

.tickets_body_right {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: flex-start;
  border-left: 1px solid var(--btn-color-1) !important;
  padding-right: 20px;
  background-color: transparent;
  border: none;
  color: var(--white-color);
}

.banner_containr_box_btns_options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.banner_containr_box_btns_options > button:nth-child(1) {
  border-radius: 6px;
  background: #f5f5f5;
  border: none;
  padding: 0.3rem 1rem;

  color: var(--btn-color-1);
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner_containr_box_btns_options > button:nth-child(2) {
  border-radius: 6px;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--btn-color-1);
  background: rgba(82, 229, 194, 0);

  color: var(--btn-color-1);
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner_containr_box_btns_options button:hover {
  background-color: var(--btn-color-1);
  color: var(--white-color);
}

.tickets_body_right:hover {
  background-color: var(--btn-color-1);
  color: var(--white-color);
}

/* .tickets_body_left:hover {
  background-color: var(--btn-color-1);
  color: var(--white-color);
} */

.trailers_modal :global(.modal-dialog) {
  max-width: 60%;
  width: 60%;
  height: auto;
}

.trailers_modal :global(.modal-content) {
  background-color: #121212; /* Dark background */
  color: white; /* White text */
  border-radius: 10px;
  padding: 20px;
}

.trailers_modal :global(.btn-close) {
  background-color: white !important; /* White close icon */
  opacity: 1; /* Make it fully visible */
  filter: invert(1); /* Invert color for dark mode */
  width: 24px;
  height: 24px;
  color: aliceblue !important;
}

.trailers_modal :global(.btn-close:hover) {
  opacity: 0.7; /* Reduce opacity on hover */
}

.modal_body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.video_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_container_video {
  width: 100%;
  max-height: 100%;
}

.watch_trailer_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.watch_trailer_btn button {
  border-radius: 6px;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--btn-color-1);
  background: rgba(82, 229, 194, 0);

  color: var(--btn-color-1);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.disabled {
  color: var(--text-color-6) !important;
  background-color: transparent !important;
  opacity: 0.5;
  cursor: not-allowed;
  /* cursor: crosshair; */
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled label {
  color: var(--text-color-6);
  background-color: transparent !important;
  opacity: 0.5;
  cursor: not-allowed;
  /* cursor: crosshair; */
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled:hover {
  color: var(--text-color-6) !important;
  background-color: transparent !important;
}

.disabled_btn {
  opacity: 0.5;
  cursor: not-allowed;
}

.banner_containr_box_footer p {
  color: var(--text-color-6);
}

@media screen and (min-width: 270px) and (max-width: 767px) {
  .banner_containr {
    height: auto;
  }
  .banner_containr_box_body_tags_genre {
    display: none;
  }
}

@media screen and (min-width: 270px) and (max-width: 600px) {
  .banner_containr {
    height: auto;
  }

  .banner_containr_box_btns {
    flex-direction: column;
    gap: 1rem;
  }
  .banner_containr_box_btns_options {
    gap: 1rem;
  }
  .banner_containr_box_body_info {
    width: auto;
  }

  .trailers_modal :global(.modal-dialog) {
    max-width: 90%;
    width: 90%;
    height: auto;
  }

  .tickets {
    font-size: x-small;
  }

  .banner_containr_box > label,
  p {
    font-size: small;
  }

  .banner_containr_box_body_tags_genre {
    display: none;
  }
}
