.support_container {
  padding-top: 2rem;
}
.support_container_box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.support_container_box_help {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.support_container_box_help_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  /* width: 20%; */
  padding: 1rem;
}

.support_container_box_help_left h4 {
  color: var(--white-color);
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 72px */
}

.support_container_box_help_left p {
  color: var(--text-color-6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.support_container_box_help_left div {
  width: 90%;
  height: 300px;
  border-radius: 12px;
  border: 1px solid var(--Black-15, #262626);
  background: rgb(2, 0, 36);
  background: linear-gradient(
      180deg,
      #16262abf 8%,
      rgba(20, 20, 20, 0) 50%,
      #1b2e2fc4 100%
    ),
    url("../../assets/images/welcomeScreenBg.png");
  background-size: cover;
  background-position: center;
}
.support_container_box_help_right {
  background: var(--bg-color-3);
  width: 60%;
  display: flex;
  padding: 50px;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 50px;

  border-radius: 12px;
  border: 1px solid var(--background-color-8);
  background: var(--background-color-7);
}

.support_container_box_help_right form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.support_container_box_help_right_name {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.support_container_box_help_right_name div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.support_container_box_help_right_name div label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.support_container_box_help_right_name div input {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--bg-color-3);
  padding: 0.5rem 1rem;
  color: var(--white-color);
}

.support_container_box_help_right_email {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.support_container_box_help_right_email div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.support_container_box_help_right_email label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.support_container_box_help_right_email div input {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--bg-color-3);
  padding: 0.5rem 1rem;
  color: var(--white-color);
}

.support_container_box_help_right_email div select {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--bg-color-3);
  padding: 0.5rem 1rem;
  color: var(--white-color);
}

.support_container_box_help_right_message {
}

.support_container_box_help_right_message div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.support_container_box_help_right_message label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}
.support_container_box_help_right_message div textarea {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--bg-color-3);
  padding: 0.5rem 1rem;
  color: var(--white-color);
}

.support_container_box_help_right_message div input {
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--bg-color-3);
  padding: 0.5rem 1rem;
  color: var(--white-color);
}

.support_container_box_help_right_btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.support_container_box_help_right_btns div {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.support_container_box_help_right_btns div input {
  width: 20px;
  height: 20px;
}

.support_container_box_help_right_btns button {
  border-radius: 15px;
  background: var(--btn-color-1);
  border: none;
  width: 40%;
  height: 3rem;
  display: flex;
  color: var(--white-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  align-items: center;
}

.support_container_box_faqs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.support_container_box_faqs_header {
  display: flex;
  flex-direction: column;
}

.support_container_box_faqs_header p {
  color: var(--Grey-60, #999);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

/* list */

.support_container_box_tickets {
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid var(--Black-15, #262626);
  background: var(--background-color-7);
}

.support_container_box_tickets_header {
}

.support_container_box_tickets_header h4 {
  color: var(--white-color);
  /* font-size: 33px; */
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 72px */
}

.support_container_box_tickets_header p {
  color: var(--text-color-6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.support_container_box_tickets_body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.support_container_box_tickets_body_header,
.support_container_box_tickets_body_items {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
  gap: 10px;
  padding: 10px;
  /* border-bottom: 1px solid #eee; */
  border-radius: 8px;
  border: 1px solid var(--background-color-8);
  background: var(--bg-color-3);
  /* padding: 0.5rem 1rem; */
  color: var(--white-color);
}

.support_container_box_tickets_body_header {
  font-weight: bold;
  background-color: transparent;
  padding: 10px;
  border-radius: 4px;
}

.support_container_box_tickets_body_items {
  font-size: 14px;
}

.support_container_box_tickets_body_items:nth-child(even) {
  /* background-color: #fafafa; */
}

@media screen and (min-width: 721px) and (max-width: 1200px) {
  .support_container {
    padding: 0.5 rem;
  }
  .support_container_box_help {
    flex-direction: row;
  }
  .support_container_box_help_right_name {
    flex-direction: column;
  }
  .support_container_box_help_right_email {
    flex-direction: column;
  }
  .support_container_box_help_left {
    padding: 0.2rem;
  }
  .support_container_box_help_right {
    width: 100%;
  }
  .support_container_box_help_right_btns {
    gap: 10px;
    flex-direction: column;
  }
  .support_container_box_help_left div {
    width: 100%;
  }
  .support_container_box_help_right_btns button {
    width: 100%;
  }
}

@media screen and (min-width: 230px) and (max-width: 720px) {
  .support_container {
    padding: 0.5 rem;
  }
  .support_container_box_help {
    flex-direction: column;
    gap: 1rem;
  }
  .support_container_box_help_right_name {
    flex-direction: column;
  }
  .support_container_box_help_right_email {
    flex-direction: column;
  }
  .support_container_box_help_left {
    padding: 0.2rem;
  }
  .support_container_box_help_right {
    width: 100%;
  }
  .support_container_box_help_right_btns {
    gap: 10px;
    flex-direction: column;
  }
  .support_container_box_help_left div {
    width: 100%;
  }
  .support_container_box_help_right_btns button {
    width: 100%;
  }

  .support_container_box_tickets {
    padding: 1.5rem;
  }

  .support_container_box_tickets_body_header,
  .support_container_box_tickets_body_items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1px;
    padding: 5px;
    font-size: x-small;
    /* border-bottom: 1px solid #eee; */
    border-radius: 8px;
    border: 1px solid var(--background-color-8);
    background: var(--bg-color-3);
    /* padding: 0.5rem 1rem; */
    color: var(--white-color);
  }
}
